import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0031 from '../../../components/molecules/columnDetailInner0031'
import UsefulFunctionLayout001 from '../../../components/molecules/usefulFunctionLayout001'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0031 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '深夜労働とはそもそも何？時間帯や割増賃金、制限の対象を解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="深夜労働とは？何時から？割増賃金や法律について解説します"
        description="深夜労働とは午後10時から午前5時までの間に行われる労働のことです。深夜労働の際は、雇用者の年齢と割増賃金に注意することが必要です。"
        ogUrl="https://kintaicloud.jp/column/details/C0031/"
        ogType="article"
        ogTitle="深夜労働とは？何時から？割増賃金や法律について解説します"
        ogDescription="深夜労働とは午後10時から午前5時までの間に行われる労働のことです。深夜労働の際は、雇用者の年齢と割増賃金に注意することが必要です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0031.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0031 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout001 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0031

export const pageQuery = graphql`
  query C0031 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
