import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0031 = () => (
  <Wrapper>
    <ColumnH1 label="深夜労働とは？何時から？割増賃金や法律について解説します" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.07.28</p>
    <section>
      <img src="/images/column/details/c0031.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        長時間労働が問題視されている中、削減が求められている内容の一つに
        <span>「深夜労働」</span>
        が挙げられます。深夜労働は、夜間という一般的には休息時間に充てる者が多い時間帯に行う労働であることから、一定率の割増賃金の支払や、制限を強いられる対象者の内容が詳細にわたり定められています。
        <br />
        <br />
        今回は、深夜労働とはそもそもどのような労働をいうのか、具体的な内容や割増賃金の計算法、制限の内容について、順に解説をしていきましょう。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">「深夜労働」とは</a>
          </li>
          <li>
            <a href="#toc2">深夜残業における割増賃金</a>
          </li>
          <li>
            <a href="#toc3">深夜労働の制限対象とは</a>
            <ul>
              <li>
                <a href="#toc3-1">①年少者に対する制限</a>
              </li>
              <li>
                <a href="#toc3-1">②女性の労働者に対する制限</a>
              </li>
              <li>
                <a href="#toc3-1">③育児を行う労働者に対する制限</a>
              </li>
              <li>
                <a href="#toc3-1">④介護を行う労働者に対する制限</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc4">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>「深夜労働」とは</h1>
      </div>
      <p>
        深夜労働とは、<span>午後10時から午前5時までの間</span>
        に行われる労働をいいます。
        <br />
        なお、厚生労働大臣が「必要である」と判断し、それを認めた場合は、特例として午後11時から午前6時までの間を深夜労働時間と定める場合もあります。この特例は、小売店や飲食業を営む企業等が、夜間による顧客獲得を目的に深夜労働時間帯の変更を求める場合などを想定して定められているものの、現時点で深夜労働時間の変更が認められたというケースは報告がされていないことが実情です。
        <br />
        あくまでも、深夜労働時間に相当する時間帯は「午後10時から午前5時まで」という事を覚えておきましょう。
      </p>
      <div id="toc2">
        <h1>深夜残業における割増賃金</h1>
      </div>
      <p>
        給与に上乗せして支給することが求められる「割増賃金」には主に次の3種類の内容が挙げられます。
        <br />
        まず、いわゆる残業代の支払いが必要となる<span>「時間外労働手当」</span>
        、休日出勤をさせた場合に支払いが必要となる「<span>休日労働手当」</span>
        、そして、深夜労働が行われた際に支払いが必要となる
        <span>「深夜労働手当」</span>になります。
        <br />
        深夜労働が行われた場合は、通常に支払われる賃金の2割5分以上の割増賃金を支払わなければなりません。あくまでも、2割5分「以上」となるため、2割5分よりも高い率で割増賃金を支払うことも認められています。
        <br />
        <br />
        なお、複数の支払要件が重なった場合の割増賃金は、それぞれの割増賃金率を加算した上で、計算をしなければなりません。
        <br />
        たとえば、所定労働時間が午前9時から午後6時まで（昼休憩1時間あり）の企業に勤める社員が定時後も残業を行い、それが深夜労働時間帯となる午後11時まで及んだ場合は、以下のように割増賃金の計算が行われます。
        <br />
        <br />
        午前9時から午後6時までの労働：通常の賃金
        <br />
        午後6時から午後10時までの労働：通常の賃金＋時間外労働時の割増賃金（2割5分）
        <br />
        午後10時から午後11時までの労働：通常の賃金＋時間外労働時の割増賃金＋深夜労働時の割増賃金（2割5分＋2割5分＝5割）
        <br />
        <br />
        なお、休日労働日に深夜残業を行った場合は、深夜残業時間帯の労働については、通常の賃金＋休日出勤時の割増賃金＋深夜労働時の割増賃金（3割5分＋2割5分＝6割）という高い割増賃金率での計算が必要となります。
        <br />
        この数字からも、労働者が休日出勤による作業が深夜まで及ぶという非常に過酷なスケジュールをこなした場合は、企業側は充分な報酬を支払わなければならないことがお分かりいただけるかと思います。
      </p>
      <Introduction003 />
      <div id="toc3">
        <h1>深夜労働の制限対象とは</h1>
      </div>
      <p>
        深夜労働は、労働者の心身に影響を及ぼしかねない労働となることから、性別や年齢など、負担が大きいと判断される者に対しては、次のような制限が設けられています。
      </p>
      <div id="toc3-1">
        <h2>①年少者に対する制限</h2>
      </div>
      <p>
        年少者とは、18歳未満の者をいいます。この年少者に関しては、
        <span>健康面や福祉面</span>
        などによる観点から、原則として深夜労働をさせることは禁止されています。
        <br />
        <br />
        ただし、例外として、満16歳以上の男性に関しては、
        <span>交替制による深夜労働が認められています</span>
        。交替制は、一定の期間ごとに昼間の勤務・深夜の勤務へ交代で就く勤務形態のことで、定期的な交代勤務による深夜労働の疲労を回復させることができると判断された上でのルールとなります。
        <br />
        また、災害などによる臨時性の高い急用が生じた場合も、行政官庁の許可を得た上で年少者の深夜労働が認められます。
        <br />
        さらに、農林、畜産・水産業や、病院・保健衛生業、電話通信業に従事する年少者の場合は、業務形態上やむを得ないと判断されることから、年少者の深夜残業が認められています。
      </p>
      <div id="toc3-2">
        <h2>②女性の労働者に対する制限</h2>
      </div>
      <p>
        女性労働者の深夜労働は、原則として認められてはいるものの、防犯面からの危険性を防ぐため、安全確保措置を行う必要があります。また、子を養育する女性労働者や、要介護の家族を介助する女性労働者の場合は、労働者からの請求があった際には深夜労働をさせることが禁じられています。
      </p>
      <div id="toc3-3">
        <h2>③育児を行う労働者に対する制限</h2>
      </div>
      <p>
        小学校に就学する前の子を育てる労働者に対しては、企業活動に支障がないケースに限り、労働者の請求に応じる形で深夜労働の制限を行う必要があります。ただし、継続雇用期間が1年未満の者や、子を養育する他の同居家族がいる場合、そもそも所定労働時間がすべて深夜に及ぶ企業などの場合などは、労働者からの「深夜労働ができない」旨の請求は無効になります。
        <br />
        深夜労働の制限に関する制限については、制限を希望する労働者は
        <span>制限開始期間の1ヶ月前までに申請</span>をしなければなりません。
      </p>
      <div id="toc3-4">
        <h2>④介護を行う労働者に対する制限</h2>
      </div>
      <p>
        要介護者を介護する労働者に対しては、育児中の労働者の場合と同じく、企業活動に支障がないケースに限り、労働者の請求に応じる形で深夜労働の制限を行う必要があります。
        <br />
        制限を希望する労働者が、制限開始期間の1ヶ月前までに申請をしなければならない点についても、育児中の労働者と同様の要件となります。
      </p>
      <div id="toc4">
        <h1 className="read">まとめ</h1>
      </div>
      <p>
        深夜労働については、企業側によるさまざまな配慮が必要であるという事がお分かりいただけたかと思います。各労働者の制限内容についても、まずは社内の現状を洗い出した上で、あいまいなルールしか存在していない場合には就業規則などで明確なルール付けを行いましょう。
        <br />
        社員の健康に影響をおよぼす労働となりますので、長時間の残業が常態化している企業は、
        <span>体制を一から見直す</span>必要もあるでしょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0031
